/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import cuid from 'cuid';
import AOS from 'aos';
import 'aos/dist/aos.css';
// import axios from 'axios';
// import publicIp from 'public-ip';
// import emailjs from 'emailjs-com';
import { Line } from 'react-chartjs-2';
// import { Panel } from 'react-bootstrap/lib';
import {
  comments,
  useCaseDescriptions,
  rhysEngagementOptions
} from '../utils/helpers';
import ReactGA from 'react-ga';
import Layout from '../layouts/index';
import SEO from '../components/seo';
// import LogoSVG from '../images/imgcomps/LogoSVG';
import '../styles/Components/home.scss';
// import ReachInstaImage from '../images/reach-ontop-black.jpg';
import IGLogo from '../images/glyph-logo_May2016.png';
import AnchorLogo from '../images/anchor-logo-header.webp';
import FBLogo from '../images/facebook-2048-black.png';
// import ReachLogo from '../images/1024x1024reach.png';
import StarWrapper from '../components/Common/StarWrapper';
// import Conversation from '../components/Conversation.js';
// import FakeMediaModal from '../components/Modal/FakeMediaModal';
import DefaultButton from '../components/Input/DefaultButton';

const Arrow = () => (
  <svg width="14px" height="15px" viewBox="0 0 14 15" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Desktop-HD" transform="translate(-713.000000, -570.000000)" fill="#D03724">
        <g id="Group-2" transform="translate(713.000000, 570.000000)">
          <path d="M6.92307692,0 L6.92307692,0 C7.56032856,-1.17061226e-16 8.07692308,0.516594519 8.07692308,1.15384615 L8.07692308,13.7142857 L5.76923077,13.7142857 L5.76923077,1.15384615 C5.76923077,0.516594519 6.28582529,1.17061226e-16 6.92307692,0 Z" id="Rectangle" />
          <path d="M9.7753766,6.26487421 L9.7753766,6.26487421 C10.3779513,6.26199108 10.8687718,6.74813714 10.871655,7.35071182 C10.871744,7.36931806 10.871357,7.38792353 10.8704945,7.40650998 L10.484387,15.7267151 L8.58306891,15.4571819 L8.62153045,7.41872037 C8.62457345,6.78273345 9.13938968,6.26791721 9.7753766,6.26487421 Z" id="Rectangle" transform="translate(9.753399, 10.993047) rotate(45.000000) translate(-9.753399, -10.993047) " />
          <path d="M4.17623428,6.27336573 L4.17623428,6.27336573 C4.78235776,6.27046562 5.27606893,6.75947477 5.27896904,7.36559825 C5.27904832,7.38216808 5.27875234,7.39873757 5.27808131,7.41529399 L4.9432284,15.677223 L2.98392659,15.4656734 L3.02238813,7.42721188 C3.02543113,6.79122496 3.54024736,6.27640873 4.17623428,6.27336573 Z" id="Rectangle" transform="translate(4.154256, 10.972547) scale(-1, 1) rotate(45.000000) translate(-4.154256, -10.972547) " />
        </g>
      </g>
    </g>
  </svg>
);
const IndexPage = () => {
  if (typeof AOS.init === 'function') {
    AOS.init({
      once: true,
    });
  }
  const [media, setMedia] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [hasCode, setHasCode] = useState(false);
  const [openMedia, setOpenMedia] = useState(undefined);
  const [selectedUseCase, setSelectedUseCase] = useState("use-case-item-1");

  const initializeReactGA = () => {
    ReactGA.initialize('UA-150236167-1');
    ReactGA.pageview('/homepage');
  };

  const importAll = (r) => {
    const mediaArr = [];

    r.keys().map((item, index) => {
      mediaArr.push({
        media_url: window.location.origin + r(item).replace('./', ''),
        media_type: 'IMAGE',
        comments: [comments[index]],
        id: index.toString(),
      });
    });
    const data = {
      data: mediaArr,
      next: undefined,
    };
    return data;
  };
  useEffect(() => {
    // Google Analytics
    initializeReactGA();

    const mediaObjects = importAll(require.context('../images/instagram_feed', false, /\.jpg/));
    setMedia(mediaObjects.data);
    setHasCode(true);
  }, []);

  const displayMediaItem = (mediaItem) => {
    setOpenMedia(mediaItem);
    setOpenModal(true);
    // Handle Modal for media object;
  };

  const closeModal = () => {
    setOpenMedia(undefined);
    setOpenModal(false);
  };

  function onItemClick(event){
    event.preventDefault();
    Array.from(document.getElementsByClassName("use-case-item")).forEach(element => {
      if(element.id == event.target.id){
        element.classList.add("selected");
        setSelectedUseCase(element.id);
      } else {
        element.classList.remove("selected");
      }
    });
  }

  const engagementData = {
    labels: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    datasets: [
      {
        label: 'Engagement Rate',
        backgroundColor: 'rgba(237, 73, 51,0.0125)',
        borderColor: 'rgba(237, 73, 51,1)',
        pointBackgroundColor: "rgba(237, 73, 51,1)",
        borderWidth: 2,
        hoverBackgroundColor: 'rgba(237, 73, 51,1)',
        hoverBorderColor: 'rgba(237, 73, 51,1)',
        fillColor: "rgba(151,205,187,0.2)",
        strokeColor: "rgba(151,205,187,1)",
        pointColor: "rgba(151,205,187,1)",
        pointStrokeColor: "#fff",
        pointHighlightFill: "#fff",
        pointHighlightStroke: "rgba(151,205,187,1)",
        data: [6.5, 5.9, 8.0, 8.1, 5.6, 5.5, 4.0],
      },
    ],
  };

  const estimatedCommunityData = {
    labels: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    datasets: [
      {
        label: 'Community Size',
        backgroundColor: 'rgba(0, 214, 180,0.0125)',
        borderColor: 'rgba(0, 214, 180,1)',
        borderWidth: 2,
        hoverBackgroundColor: 'rgba(0, 214, 180,1)',
        hoverBorderColor: 'rgba(0, 214, 180,1)',
        data: [138, 285, 319, 417, 555, 870, 1226],
      },
    ],
  };

  const retentionData = {
    labels: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
    datasets: [
      {
        label: 'Profile Retention',
        backgroundColor: 'rgba(208, 72, 182,0.0125)',
        borderColor: 'rgba(208, 72, 182,1)',
        borderWidth: 2,
        hoverBackgroundColor: 'rgba(208, 72, 182,1)',
        hoverBorderColor: 'rgba(208, 72, 182,1)',
        data: [(138 / 108) * 100, (185 / 108) * 100, (119 / 108) * 100, (117 / 108) * 100, (155 / 108) * 100, (170 / 108) * 100, (126 / 108) * 100],
      },
    ],
  };

  return (
    <Layout>
      <SEO title="Reach Social Growth" />
      <div className="home">
        <StarWrapper />
        <section className="home-container">
          <div className="logo-container">
          <div className="value-prop-card" data-aos="fade-left" data-aos-duration="1000" data-aos-delay="500">
              <div className="card">
                <h3>Engagement Performance</h3>
                <div className="card-body">
                  <Line id="defaultChart" data={engagementData} options={rhysEngagementOptions} />
                </div>
              </div>
            </div>
            <div className="value-prop-container" data-aos="fade-right" data-aos-duration="1000" data-aos-delay="500">
              <h2 className="bold-title">Unlock Social</h2>
              <h3 className="bold-statement">
                using our <a className="value-prop-link" href="#contextual-analytics">Contextual Analytics </a>
                + <br></br><a className="value-prop-link" href="#comment-ai">Industry Specific Comment AI</a>
              </h3>
              <DefaultButton
                text="Get Started"
                callback={() => {window.location = "/sign-up"}}
              />
            </div> 
          </div>
        </section>
        <div className="opposite-seperator" />
        <section id="use-cases" className="try-container opposite">
          <h2>Use Cases</h2>
          <div className="use-cases-container">
            <div className="use-case-items">
              <div className="use-case-item selected" id="use-case-item-1" onClick={onItemClick}>
                {useCaseDescriptions["use-case-item-1"].title}
              </div>
              <div className="use-case-item" id="use-case-item-2" onClick={onItemClick}>
                {useCaseDescriptions["use-case-item-2"].title}
              </div>
              <div className="use-case-item" id="use-case-item-3" onClick={onItemClick}>
                {useCaseDescriptions["use-case-item-3"].title}
              </div>
            </div>
            <div className="selected-use-case">
              {selectedUseCase !== "" && (
                <>
                  <div>
                    <h3>{useCaseDescriptions[selectedUseCase].title}</h3>
                      <p>{useCaseDescriptions[selectedUseCase].description}</p>
                    <DefaultButton
                      text="Get Started"
                      callback={() => {window.location = "/sign-up"}}
                    />
                  </div>
                  <img src={require("../images/use_cases/" + selectedUseCase.split("-")[3] + ".jpg")} alt={useCaseDescriptions[selectedUseCase].image_alt}></img>
                </>)
              }
            </div>
          </div>
        </section>
        <div className="seperator" />
        <section id="as-seen-on" className="try-container">
          <h2>As Seen On</h2>
          <div className="press-container">
            <div className="press">
              <a href="https://instagram.com/reachsocialgrowth" target="_blank"><img alt="Instagram Logo" src={IGLogo}>
              </img></a>
            </div>
            <div className="press">
              <a href="https://anchor.fm/reachsocialgrowth" target="_blank"><img alt="Anchor Logo" src={AnchorLogo}>
              </img></a>
            </div>
            <div className="press">
              <a href="https://facebook.com/reachsocialgrowth" target="_blank"><img alt="Facebook Logo" src={FBLogo}>
              </img></a>
            </div>
          </div>
        </section>
        <section id="as-seen-on" className="try-container">
          <div className="cta">
            <div className="cta-text-container">
              <h2>Join a small batch of creators, brands and people that are rediscovering social.</h2>
              <p>You can’t find a platform like this anywhere else. Get more out of your account using our contextual analytics or let our Industry Specific Comment AI take over and automate replies to your incoming comments!</p>
              <a href="/sign-up">Get Started</a>
            </div>
          </div>
        </section>
      </div>

    </Layout>
  );
};

export default IndexPage;
